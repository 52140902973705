import React from "react"
import styled from "styled-components"
import Layout from "../components/core/Layout"
import SEO from "../components/core/Seo"
import fonts from "../styles/fonts"
import colors from "../styles/colors"
import BlueLineImg from "../resources/images/brewery/blue-line.svg"
import { Fade } from "react-reveal"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import Img from "gatsby-image"

const PageContainer = styled.div`
  background: ${colors.darkNavyBackground};
  width: 100%;
  height: 100%;
  max-width: 100vw;
  margin-top: -138px;
  padding: 0 33px 57px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  @media (min-width: 768px) {
    padding-bottom: 126px;
  }
`

const StyledTitle = styled.div`
  text-align: center;
  margin-bottom: 60px;
  position: relative;
  z-index: 1;
  @media (min-width: 768px) {
    margin-bottom: 100px;
  }
`
const OutlinedLine = styled.h1`
  text-transform: uppercase;
  font-size: 46px;
  letter-spacing: 4px;
  color: transparent;
  letter-spacing: 3px;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: white;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 46px;
  ${fonts.swissBlackExtended}
  padding-top: 185px;
  padding-bottom: 22.5px;
  @media (min-width: 768px) {
    font-size: 78px;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 2px;
    padding-top: 240px;
    line-height: 87px;
    padding-bottom: 28.5px;
  }
`

const Content = styled.div`
  ${fonts.gilroyRegular};
  color: ${colors.white};
  position: relative;
  z-index: 1;
  h2 {
    ${fonts.swissBlackExtended};
    font-size: 14px;
    letter-spacing: 0.88px;
    line-height: 20px;
    text-transform: uppercase;
    padding-bottom: 22px;
    padding-top: 30px;
    :first-of-type {
      padding-top: 0;
    }
  }
  h3 {
    ${fonts.gilroyBold};
    font-size: 15px;
    line-height: 21px;
    text-transform: uppercase;
  }

  p {
    font-size: 15px;
    line-height: 21px;
    padding-bottom: 24px;
    :last-of-type {
      padding-bottom: 0;
    }
  }
  a {
    color: ${colors.white};
    text-decoration : none;
  }
  b,
  strong {
    ${fonts.gilroyBold};
  }
  i {
    font-style: italic;
  }
  @media (min-width: 768px) {
    max-width: 744px;
    margin: auto;
    h2 {
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 19px;
      padding-bottom: 25px;
    }
    h3 {
      padding-bottom: 3px;
    }
    p {
      padding-bottom: 28px;
    }
  }
`

const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 30%;
`

const Mobile = styled(Img)`
  img {
    height: 100%;
  }
  @media (min-width: 768px) {
    display: none;
  }
`
const Desktop = styled(Img)`
  display: none;
  @media (min-width: 768px) {
    display: block;
    min-height: 730px;
    img {
      height: 100%;
    }
  }
`

const BackgroundImage = styled.div`
  padding-bottom: ${props => props.top || props.middle ? "523px" : props.bottom && "836px"};
  width: 100%;
  @media (min-width: 768px) {
    padding-bottom: ${props => props.top ? "360px" : props.middle ? "460px" : props.bottom && "380px"};
  }
`

const renderDocument = document => {
  const Text = ({ children }) => <p>{children}</p>
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
    renderText: text =>
      text.split("<br>").flatMap((text, i) => [i > 0 && <br />, text]),
  }
  return documentToReactComponents(document, options)
}

const textPage = props => {
  const path = props.location.pathName
  const pageData = props.data.page
  const {
    background1,
    background2,
    background3,
    mobileBackground1,
    mobileBackground2,
    mobileBackground3,
  } = props.data
  const { metaTitle, metaDescription, title, content } = pageData

  const background = [
    {
      order: 1,
      mobile: mobileBackground1.childImageSharp.fluid,
      desktop: background1.childImageSharp.fluid,
    },
    {
      order: 2,
      mobile: mobileBackground2.childImageSharp.fluid,
      desktop: background2.childImageSharp.fluid,
    },
    {
      order: 3,
      mobile: mobileBackground3.childImageSharp.fluid,
      desktop: background3.childImageSharp.fluid,
    },
  ]

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        fullTitle={false}
        path={path}
      />
      <PageContainer>
        <StyledTitle>
          <OutlinedLine>{title}</OutlinedLine>
          <Fade duration={500} delay={500}>
            <img src={BlueLineImg} alt="blue decorative line" />
          </Fade>
        </StyledTitle>
        <Content>{renderDocument(content.json)}</Content>
        <BackgroundContainer>
          {background.map((background, index) => {
            return (
              <BackgroundImage key={index} top={background.order === 1} middle={background.order === 2} bottom={background.order === 3}>
                <Mobile fluid={background.mobile} alt="background image" />
                <Desktop fluid={background.desktop} alt="background image" />
              </BackgroundImage>
            )
          })}
        </BackgroundContainer>
      </PageContainer>
    </Layout>
  )
}

export default textPage

export const pageQuery = graphql`
  query textPageQuery($slug: String!) {
    page: contentfulTextPage(slug: { eq: $slug }) {
      metaTitle
      metaDescription
      title
      content {
        json
      }
    }
    background1: file(
      relativePath: { eq: "legals/legals-background-opt-01.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    background2: file(
      relativePath: { eq: "legals/legals-background-opt-02.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    background3: file(
      relativePath: { eq: "legals/legals-background-opt-03.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mobileBackground1: file(
      relativePath: { eq: "legals/legals-background-mobile-opt-1.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxHeight: 850) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mobileBackground2: file(
      relativePath: { eq: "legals/legals-background-mobile-opt-2.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxHeight: 850) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mobileBackground3: file(
      relativePath: { eq: "legals/legals-background-mobile-opt-3.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxHeight: 850) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
